// ** Initial user ability
export const initialAbility = [
  {
    action: 'read',
    subject: 'welcome'
  },
  {
    action: 'read',
    subject: 'comic',
    authorize: 'own'
  },
  {
    action: 'update',
    subject: 'comic',
    authorize: 'own'
  }
]


export const _ = undefined
