// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

// eslint-disable-next-line
const config = useJwt.jwtConfig

const initialGuildData = () => {
  const guilds = window.localStorage.getItem('guilds')
  //** Parse stored json or if none return initialValue
  return guilds ? JSON.parse(guilds) : {}
}

export const discordSlice = createSlice({
  name: 'discord',
  initialState: {
    guilds: initialGuildData()
  },
  reducers: {
    setGuildData: (state, action) => {
      state.guilds = action.payload
      localStorage.setItem('guilds', JSON.stringify(action.payload))
    }
    // removeGuildData: (state) => {
    //   state.guilds = {};
    //   localStorage.removeItem('guilds');
    // },
  }
})

export const { setGuildData } = discordSlice.actions

export default discordSlice.reducer
